@font-face {
  font-family: 'Work Sans';
  src:
    local('Work Sans Thin'),
    url('~assets/fonts/WorkSans-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Work Sans';
  src:
    local('Work Sans Extra Light'),
    url('~assets/fonts/WorkSans-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Work Sans';
  src:
    local('Work Sans Light'),
    url('~assets/fonts/WorkSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Work Sans';
  src:
    local('Work Sans Regular'),
    url('~assets/fonts/WorkSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Work Sans';
  src:
    local('Work Sans Regular'),
    url('~assets/fonts/WorkSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Work Sans';
  src:
    local('Work Sans Regular'),
    url('~assets/fonts/WorkSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Work Sans';
  src:
    local('Work Sans Bold'),
    url('~assets/fonts/WorkSans[wght].ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
