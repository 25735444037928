$api: 'modern-compiler';
@import 'scss/utils';

@layer tailwind-base, antd;

@layer tailwind-base {
  @tailwind base;
}
@tailwind components;
@tailwind utilities;

@layer base {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-semibold;
  }
}
